import "./App.css";
import React from "react";
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles";

export default function ThemeProvider({ children }) {
  const theme = createTheme({
    props: {
      MuiTextField: {
        variant: "outlined",
        size: "small",
      },
    },
    overrides: {
      MuiTypography: {
        body1: {
          whiteSpace: "pre-wrap",
        },
      },
    },
    palette: {
      primary: {
        main: "#FFC30E",
        // light: "#734497",
      },
      secondary: {
        main: "#D679B0",
      },
      info: {
        main: "#5EBFA4",
      },
      success: {
        main: "#84BF04",
      },
      error: {
        main: "#EF476F",
      },
      text: {
        main: "#4A4A68",
        light: "#fff",
      },
      backgorund: {
        default: "#F9F9FC",
      },
    },
    typography: {
      fontFamily: ["Noto Sans TC", "sans-serif"].join(","),
    },
  });
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}
