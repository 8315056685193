import React from 'react'
import {
  Typography,
  Grid,
  makeStyles,
  Box,
  Container,
  useTheme
} from '@material-ui/core'
import TitleIntroduce from './../components/TitleIntroduce'

import { ReactComponent as FBIcon } from "../svgs/ReceivedPaymentPage/facebook.svg";
import { ReactComponent as LinkedIcon } from "../svgs/ReceivedPaymentPage/linked.svg";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import InstagramIcon from '@material-ui/icons/Instagram';

export default function ReceivedPaymentPage() {
    const titleData = [
      {
        title: "帳單收款",
        subTitle: "即時收款超便利",
        content: [
          "帳單可於實體ATM、網路銀行或超商進行支付，為你的用戶提供簡便、安全的支付體驗。",
        ],
      },
    ];

    return (
     <Box>
       <TitleIntroduce titleData={titleData}/>
       <ToolsIntroduce/>
       <BenefitContent/>
     </Box>
    );
}

function ToolsIntroduce(){
  const theme = useTheme();
  const useStyles = makeStyles((theme) => ({
    box:{
      height: '100%',
      minHeight:'330px',
    },
    iconBox:{
      display:'flex',
      flexDirection:'column',
      alignItems:'center',
      marginTop:'15px'
    },
    icon:{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width:'64px',
      height:'64px',
      borderRadius:'100%',
      background: theme.palette.primary.main
    },
  }));
  const classes = useStyles();
  return (
    <Box className={classes.box}>
      <Container maxWidth="md">
        <Grid container justifyContent="center" style={{minHeight:'330px',height:'100%'}}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              style={{
                fontWeight: 700,
                margin: "40px 0px 10px",
                textAlign: "center",
              }}
              color="primary"
            >
              整合各種帳單收款工具
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" style={{textAlign:'center'}}>可輕鬆透過官網、Instagram、Facebook粉絲團、郵件等，發送收款連結，快速收取款項。</Typography>
          </Grid>
          <Grid item xs={12} container style={{margin:'10px 0px 40px'}}>
            <Grid item className={classes.iconBox} xs={6} md={3}>
              <Grid className={classes.icon}>
                <InstagramIcon style={{color:'#fff',width:"48px",height:"48px"}}/>
              </Grid>
            </Grid>
            <Grid item className={classes.iconBox}  xs={6} md={3}>
              <Grid className={classes.icon}>
                <FBIcon />
              </Grid>
            </Grid>
            <Grid item className={classes.iconBox}  xs={6} md={3}>
              <Grid className={classes.icon}>
                <MailOutlineIcon style={{color:'#fff',width:"48px",height:"48px"}}/>
              </Grid>
            </Grid>
            <Grid item className={classes.iconBox}  xs={6} md={3}>
              <Grid className={classes.icon}>
                <LinkedIcon/>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

function BenefitContent() {
  const useStyles = makeStyles((theme) => ({
    backImg:{
      height: "100%", 
      minHeight: "330px",
      width: '100%',
      backgroundColor:'#fff'
    }
  }));
  const classes = useStyles();
  return (
    <Box className={classes.backImg}>
      <Container maxWidth="lg">
        <Grid
          container
          style={{ height: "100%", minHeight: "330px" }}
          alignItems="center"
        >
          <Grid item xs={12}>
            <Typography
              variant="h4"
              style={{ fontWeight: 700, margin: "30px 0px" }}
              color="primary"
            >
              適用於
            </Typography>
            <Box style={{ marginLeft: "15px" }}>
              <ul>
                <li>
                  <Typography variant="body1">無經營銷售前台</Typography>
                </li>
                <li>
                  <Typography variant="body1">沒有開發技術能力</Typography>
                </li>
                <li>
                  <Typography variant="body1">快速建立帳單需求</Typography>
                </li>
              </ul>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              style={{ fontWeight: 700, margin: "30px 0px" }}
              color="primary"
            >
              付款更方便
            </Typography>
            <Box style={{ marginLeft: "15px" }}>
              <ul>
                <li>
                  <Typography variant="body1">簡單的後台管理</Typography>
                </li>
                <li>
                  <Typography variant="body1">詳細的交易報表</Typography>
                </li>
                <li>
                  <Typography variant="body1">彈性的提領撥款</Typography>
                </li>
              </ul>
            </Box>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: "30px" }}>
            <Typography
              variant="h4"
              style={{ fontWeight: 700, margin: "30px 0px" }}
              color="primary"
            >
              建立帳單非常容易
            </Typography>
            <Box >
              <Typography variant="body1">
                填寫基本資訊與費用等資料
                <br />
                確認後，即可立即發送，只需短短幾分鐘
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
