import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import {
  Button,
  Box,
  Grid,
  InputLabel,
  TextField,
  IconButton,
  InputAdornment,
  useMediaQuery,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/styles";

import { ReactComponent as LogoIcon } from "../svgs/logo.svg";
import { useToken } from "../components/TokenProvider";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

export default function LoginPage() {
  return (
    <div>
      <LoginForm />
    </div>
  );
}

function LoginForm() {
  const isPhone = useMediaQuery("(max-width:500px)");
  // const theme = useTheme()
  const useStyles = makeStyles((theme) => ({
    box: {
      minHeight: "100vh",
      backgroundColor: "white",
    },
    input: {
      height: "40px",
      padding: "0px",
      backgroundColor: "white",
    },
    labeltext: {
      color: "black",
      marginTop: "10px",
      marginBottom: "10px",
      fontSize: "14px",
      fontWeight: 400,
    },
  }));
  const classes = useStyles();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const { handleSubmit, control } = useForm({
    defaultValues: {
      userName: "",
      password: "",
    },
  });
  const { writeToken } = useToken();

  function _login(data) {
    console.log(data);
    writeToken("testToken");
    history.replace("/");
  }

  function _error(error) {
    console.log(error);
  }

  return (
    <Box>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.box}
      >
        {/* form */}
        <Grid
          container
          justifyContent="center"
          item
          xs={12}
          md={7}
          style={{ padding: "2em 0" }}
        >
          <Grid
            item
            xs={12}
            style={{
              display: isPhone ? "none" : "flex",
              justifyContent: "center",
              marginBottom: "70px",
            }}
          >
            <LogoIcon style={{ width: "300px", height: "64px" }} />
          </Grid>
          {/* 帳號 */}
          <Grid item xs={10} md={8}>
            <InputLabel className={classes.labeltext}>帳號</InputLabel>
            <Controller
              name="userName"
              control={control}
              rules={{
                required: "請填寫帳號",
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  placeholder="請輸入帳號"
                  {...field}
                  error={error}
                  helperText={error && error.message}
                  fullWidth
                  InputProps={{
                    className: classes.input,
                  }}
                />
              )}
            />
          </Grid>
          {/* 密碼 */}
          <Grid item xs={10} md={8}>
            <InputLabel className={classes.labeltext}>密碼</InputLabel>
            <Controller
              name="password"
              control={control}
              rules={{
                required: "請填寫密碼",
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  placeholder="請輸入密碼"
                  type={showPassword ? "text" : "password"}
                  {...field}
                  fullWidth
                  error={error}
                  helperText={error && error.message}
                  InputProps={{
                    className: classes.input,
                    endAdornment: (
                      <InputAdornment>
                        <IconButton
                          size="small"
                          onClick={() => setShowPassword((e) => !e)}
                        >
                          {showPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          {/* 登入註冊 */}
          <Grid
            container
            spacing={2}
            justifyContent="flex-end"
            item
            xs={10}
            md={8}
          >
            <Grid
              item
              container
              justifyContent="flex-end"
              alignItems="center"
              xs={12}
            >
              <Button
                color="primary"
                size="small"
                onClick={() => history.push("./forgot-password")}
              >
                忘記密碼?
              </Button>
            </Grid>
            <Grid container item spacing={1} style={{ marginTop: "50px" }}>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{
                    height: "48px",
                    backgroundColor: "white",
                    color: "black",
                    fontSize: "16px",
                  }}
                  onClick={() => history.push("./register")}
                  fullWidth
                >
                  註冊
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ height: "48px", fontSize: "16px" }}
                  fullWidth
                  onClick={handleSubmit(_login, _error)}
                >
                  登入
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
