import React from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export default function ServicePage() {
  return (
    <div>
      <ServiceContent />
    </div>
  );
}

function Title({ title }) {
  const theme = useTheme();
  const isTablet = useMediaQuery("(max-width:960px)");
  const useStyles = makeStyles({
    title: {
      fontWeight: "bold",
      paddingBottom: "10px",
      borderBottom: `5px solid ${theme.palette.primary.main}`,
    },
  });
  const classes = useStyles();
  return (
    <Box my={6} display="flex" justifyContent="center">
      <Typography variant={isTablet ? "h5" : "h4"} className={classes.title}>
        {title}
      </Typography>
    </Box>
  );
}

function ServiceContent() {
  const theme = useTheme();
  const isTablet = useMediaQuery("(max-width:960px)");
  const useStyles = makeStyles({});
  const classes = useStyles();
  const info = [
    {
      title: "阜冬衫金流服務平台服務條款",
      content: [
        "感謝您使用揚盛股份有限公司（以下稱「本公司」）建置之「阜冬衫金流服務平台」服務（以下稱「本服務」），本服務提供您申請使用代收款及代付款等便利功能。 使用本服務前應於消費者保護法規定之合理期間（三十日）內詳讀本服務條款，除與本公司另有書面約定外，一經使用本服務之全部或一部功能，即代表您完全同意本服務條款及相關使用規範 （包括但不限於根據本服務條款公佈於本服務網站之內容、以書面或其他形式通知之附加服務使用規範及本服務條款修正內容），若您對本服務、本服務條款及相關使用規範之任一部分有疑義或反對時，應立即停止使用本服務之全部。",
        "本文件係依據 :",
        "信用卡收單機構簽訂『提供代收代付服務平台業者』為特約商店自律規範-之規範目的及法令製作及宣導。",
      ],
    },
    {
      title: "一、名詞定義",
      content: [
        "(一)付款方：係指商品或服務之買受人，利用本服務平台付款予交易相對人，包括未於本服務註冊之使用者。",
        "(二)收款方：係指商品或服務之銷貨人，利用本平台服務收受交易相對者人支付之款項者，且必須註冊成為本平台之會員。",
        "(三)用戶：前二項所稱之付款方及收款方，均為本服務之用戶。",
        "(四)阜冬衫金流帳戶:係指本公司核發予會員之專屬帳戶，供收款方收取本公司代其向付款方收取之交易款項，或因使用本服務所生之退款使用。",
        "(五)發卡機構：係指依據「信用卡業務機構管理辦法」，經主管機關許可經營發行信用卡等相關業務且與信用卡組織有合作關係之所有國內、國外發行信用卡之機構、團體。",
        "(六)收單機構：指經各信用卡組織授權辦理特約商店簽約事宜，並於特約商店請款時，先行墊付持卡人交易帳款予特約商店之機構。",
        "(七)超商：指國內 7-11、全家、萊爾富及 ok 連鎖便利商店。",
        "(八)本服務網站：指網址為 https://fudspay.com 及本公司基於提供本服務而製作之附屬網站、網頁及其周邊服務。",
      ],
    },
    {
      title: "二、 使用資格",
      content: [
        "(一)用戶應為中華民國民法所規定具備完全行為能力之自然人或為商號、公司等依據中華民國法律成立之法人。如果用戶未滿二十歲或非具備民事完全行為能力者，尚須由用戶之法定代理人（或法院選任之特別代理人）詳閱、瞭解並同意本服務條款之所有內容，方可註冊或使用本服務。當用戶註冊或使用本服務時，即視為已取得法定代理人（或法院選任之特別代理人）同意。",
        "(二)用戶如為自然人身分者，本服務提供付款及收款功能供您選擇；用戶如係法人者，本服務僅提供收款功能。",
      ],
    },
    {
      title: "三、 服務說明",
      content: [
        "一)用戶同意本公司於付款方與收款方之網路交易成立後，扮演中立之第三人角色，代收款方收受網路交易之價金，並於一定付款條件成就時，再將代收之交易價金轉付予收款方。",
        "(二)前項所稱之付款條件成就，係指包括但不限於付款方已取得商品、獲得服務，或一定天期屆滿而付款方未提出異議等，其他付款條件成就之要件，均於本服務網站公告並提供相關功能操作。付款方並應自行確認付款條件是否成就，並應於規範時限內提出相關權利主張，任何因付款方未依本服務說明提出異議而造成之損害，本公司不負賠償之責。",
        "(三)本公司另提供用戶得串接本公司合作物流廠商之各項物流功能，包括但不限於超商取貨等服務，惟本公司及物流廠商均不參與或介入收款方之商業經營及用戶間之買賣關係，本公司及物流廠商與收款方所設立之商號、提供之產品或服務本身並無合夥、僱傭、互為代理、經銷或除代收付款項、物流服務以外之其他委任關係。",
        "(四)用戶對於本服務全部功能之操作，均須於本服務網站進行，除另有事先公告服務中止或不可抗力之連線中斷外，本服務網站提供每日二十四小時使用。所有用戶於網站上執行之操作均由系統即時接收，並依該功能說明即時或批次進行處理，除本服務網站另有說明外，用戶一經確認交易，均不得撤回、取消或修改任何操作及利用本服務網站介面所為之意思表示。",
        "(五)本服務旨在提供便利安全之收付款服務，本公司並不參與或介入收款方之商業經營及用戶間之買賣關係，本公司與收款方所設立之商號、提供之產品或服務本身並無合夥、僱傭、互為代理、經銷或除代收付款項以外之其他委任關係。",
        "(六)本服務基於服務內容所需，提供因本服務而衍生之相關操作流程及網站介面，該介面非為網際網路交易平台，不涉及居間或媒合用戶間買賣關係。",
        "(七)為維護用戶交易安全，本服務將提供價金保管之服務，若該筆交易價金屬本公司價金保管服務者，則於付款方支付交易款項後，本公司即行保管該筆款項一定期間，除本服務條款或本公司網站另有規定外，收款方不得以任何理由要求提前提領款項。",
        "(八)除法令另有規定外，本公司僅就用戶間基於實質商品或服務之交易，辦理逐筆代收轉付行為。",
        "(九)本服務所代收之支付款款項均應以新臺幣結付。",
        "(十)為維護用戶權益，如用戶對本服務有任何服務需求，或因本服務產生任何爭議時，均得與本公司客服中心聯繫向本公司提出申訴，本公司將協助處理。",
      ],
    },
    {
      title: "四、 服務內容",
      content: [
        "(一)本服務所提供之收款、付款方式，概依本公司於用戶使用當時所建置之機制為準，如前述之收款、付款方式有增減、變更或修改時，本公司將公告於相關網頁上，不另行通知。本服務各收款、付款方式及使用限制相關規範如下:",
      ],
    },
    {
      title: "1.信用卡支付",
      content: [
        "(1)信用卡收付款方式，係指於用戶完成購買商品、服務交易後，收款方接受付款方使用信用卡支付交易款項，並透過本公司系統代收款方處理因付款方使用信用卡支付款項所發生之授權取得、帳款請款、撥付等事宜。",
        "(2)收款方了解信用卡簽帳交易於付款方信用卡授權成功時，並不代表收款方或本公司已成功取得款項，實際撥款入帳時程將依本服務網站說明規範。",
        "(3)根據信用卡國際組織規定，付款方因對交易商品或服務之內容有疑義而透過發卡機構主張返還款項時，本公司得不另經收款方同意逕行退款，並自收款方暫留於本平台之款項中扣除，若收款方暫無留存於本平台之款項，本公司將以未來代收款方所收之款項抵付。",
        "(4)收款方透過本服務系統辦理請款，至遲不得逾交易成立起五日，逾期本公司不負付款之責。",
        "(5)收款方經本服務成功收取付款方以信用卡為付款方式之交易款項後，若收款方允許付款方辦理退貨、取消交易、終止服務、變更貨品及其價格相關事宜時，收款方應於付款日後九十日內，依照本服務網站說明以信用卡退刷形式進行辦理，收款方不得以現金或支票方式直接退款予付款方，該交易計收之手續費將返還予收款方之阜冬衫金流帳戶，手續費小點數以下，四捨五入，單筆未超過新臺幣 1 元者將不進行退還。",
        "(6)根據信用卡國際組織規定，收款方接受信用卡支付之交易金額超逾一定請款金額時，收款方應直接與收單機構簽訂相關契約，惟收款方同意仍委由本公司繼續處理信用卡支付之交易之相關事宜。",
        "(7)若因非可歸責於本公司之事由（如：付款方否認交易、信用卡遭盜刷等）所生之款項返還或款項無法收取情形，其損失由收款方負擔。",
        "(8)經本公司或收單機構或發卡機構出具信用卡組織、銀行或付款方所出具之可資證明之文件，證明付款方之信用卡為偽卡或遭盜刷之情形，收款方應於本公司通知時立即停止出貨。前述之本公司通知可由本公司自行選擇通知送達方式，含電話聯繫、手機簡訊或電子郵件等擇一為之，收款人不得因未及時查閱訊息、郵件，或因非可歸責本公司因素（如：網際網路服務中斷、電信營運商服務異常等）而主張本公司通知未送達。",
        "(9)收款方接受信用卡付款需配合本公司依商品銷售類別要求啟用信用卡 3D 驗証機制，如收款方不使用信用卡 3D 驗証機制，應自行承擔付款方因使用信用卡付款所生之所有爭議款項。",
        "(10)收款方提供遞延性商品或服務時，應提供相關履約保證，並應揭露該履約保證資訊予付款方知悉。",
        "(11)收款方使用信用卡收款若經收單機構通報為風險商店或調扣比例過高等事由，本公司得隨時終止向收款方提供此支付方式，收款方不得有異議或主張任何權利。",
        "(12)用戶於本平台使用信用卡交易時，以本公司為收單機構之特約商店，本公司將於信用卡交易時顯示收款方資訊供付款方知悉。",
        "(13)「信用卡支付」功能亦提供分期付款機制供用戶使用，用戶了解本交易為各發卡機構提供之信用卡分期付款交易，並同意下列事項：",
        "(A)發卡機構已將消費款一次墊付予特約商店，並同意持卡人分期繳付消費款。",
        "(B)發卡機構未介入商品交易內容，如有疑義請先洽收款方處理，如仍無法解決得聯絡發卡機構協助處理，不應拒付信用卡帳款。",
        "(C)如付款方於價金保管期間內向本公司申訴其與收款方間發生詐欺等不法情事或消費糾紛，本公司將依付款方所提供之報案三聯單或消費爭議申訴證明文件，暫停撥付該筆交易全額款項或自收款方暫留於本平台之款項中扣除，並依本服務條款第十一條第四項之規定處理之。惟付款方仍不得拒付信用卡帳款。",
      ],
    },
    {
      title: "第五條 服務內容及使用規範",
      content: [
        "本公司提供之服務內容：",
        "(一) 虛擬帳號轉帳。",
        "(二) 信用卡支付。",
        "(三) 超商代碼繳費。",
        "(四) 手機三段條碼繳費。",
        "各服務內容之收款、付款方式及使用限制相關規範如下：",
        "(一) 虛擬帳號轉帳：",
        "虛擬帳號轉帳為透過本公司虛擬帳號付款之功能，由本公司發給虛擬帳號予付款方，再由付款方利用ATM自動化設備、WebATM、網路銀行或銀行金融機構臨櫃轉帳付款之收款方式。",
        "供使用之虛擬帳號，將依交易項目而有不同的限制使用，付款方應於指定的期間內完成付款，逾時付款則該帳號將自動失效，付款方不得以此主張任何交易權利。",
        "收款方經本服務成功收取付款方以虛擬帳號轉帳為付款方式之交易款項後，若允許付款方辦理退貨、取消交易，本功能不提供退款機制，收款方應自行退款予付款方，且該交易計收之手續費將不返還收款方。",
        "(二) 信用卡支付：",
        "信用卡收付款方式，係指於付款方完成購買商品、服務交易後，收款方接受付款方使用信用卡支付交易款項，並透過本公司系統代收款方處理因付款方使用信用卡支付款項所發生之授權取得、帳款請款、撥付等事宜。",
        "收款方於付款方信用卡授權成功時，並不代表收款方或本公司已成功取得款項，實際撥款入帳時程將依本服務網站說明規範。",
        "根據信用卡國際組織規定，付款方因對交易商品或服務之內容有疑義而透過發卡機構主張返還款項時，本公司得不經收款方同意逕行退款。",
        "收款方經本服務成功收取付款方以信用卡為付款方式之交易款項後，若收款方允許付款方辦理退貨、取消交易、終止服務、變更貨品及其價格相關事宜時，應依照本服務網站說明以信用卡退刷形式進行辦理，收款方不得以現金或支票方式直接退款予付款方。",
        "根據信用卡國際組織規定，收款方接受信用卡支付之交易金額超逾一定請款金額時，收款方應直接與收單機構簽訂相關契約，惟收款方同意仍委由本公司繼續處理信用卡支付之交易之相關事宜。",
        "若因非可歸責於本公司之事由（如：付款方否認交易、信用卡遭盜刷等）所生之款項返還或款項無法收取情形，其損失由收款方負擔。",
        "經本公司或收單機構或發卡機構出具信用卡組織、銀行或收款方所出具之可資證明之文件，證明付款方之信用卡為偽卡或遭盜刷之情形，收款方應於本公司通知時立即停止出貨。前述之本公司通知可由本公司自行選擇通知送達方式，含電話聯繫、手機簡訊或電子郵件等擇一為之，收款方不得因未及時查閱訊息、郵件，或因非可歸責本公司因素（如：網際網路服務中斷、電信營運商服務異常等）而主張本公司通知未送達。",
        "收款方接受信用卡付款需配合本公司依商品銷售類別要求啟用信用卡3D驗証機制，如收款方不使用信用卡3D驗証機制，應自行承擔付款方因使用信用卡付款所生之風險及所有爭議款項。",
        "用戶於本平台使用信用卡交易時，以本公司為收單機構之特約商店，本公司將於信用卡交易時顯示收款方資訊供付款方知悉。",
        "「信用卡支付」功能亦提供分期付款機制供其使用，收款方了解本交易為各發卡機構提供之信用卡分期付款交易，並同意下列事項：",
        "(1)發卡機構已將消費款一次墊付予特約商店，並同意付款方分期繳付。",
        "(2)發卡機構未介入商品交易內容，如有疑義請先洽收款方處理，如仍無法解決得聯絡發卡機構協助處理。",
        "(3)如付款方於價金保管期間內向本公司申訴其與收款方間發生詐欺等不法情事或消費糾紛，本公司將依付款方所提供之報案三聯單或消費爭議申訴證明文件，暫停撥付該筆交易全額款項，並依本服務條款第15條之規定處理。",
        "(三) 超商代碼繳費：",
        "超商代碼繳費，即收款方透過本平台產生之代碼，提供予付款方至超商多媒體機列印繳費單後，於超商櫃台以現金全額支付之收款方式。",
        "超商多媒體機所列印之繳費單設有繳費期限，逾期未繳則該單失效。",
        "超商多媒體機列印繳費單後僅接受繳款一次，如付款方因重覆繳款所生之損害，由付款方自行承擔。",
        "依超商通路服務約定，遊戲點數、遊戲點卡或遊戲點數儲值類型產品、及電信點卡類之商品不得使用本支付方式收款，若違反經本公司或超商通路證實，則本公司有權終止此支付方式，若因收款方造成本公司任何損失，收款方應付損害賠償責任。",
        "收款方經本服務成功收取付款方以超商代碼繳費為付款方式之交易款項後，若允許付款方辦理退貨、取消交易，本功能不提供退款機制，收款方應自行退款予付款方，且該交易計收之手續費將無法返還收款方。",
        "(四) 手機三段條碼繳費：",
        "手機三段條碼繳費，即收款方將透過本平台產生之手機三段條碼顯示於阜冬衫支付 APP，提供予付款方至超商以現金全額支付之收款方式。",
        "收款方經本服務成功收取付款方以手機三段條碼繳費為付款方式之交易款項後，若允許付款方辦理退貨、取消交易，本功能不提供退款機制，收款方應自行退款予付款方，且該交易計收之手續費將無法返還收款方。",
      ],
    },
    {
      title: "2. 超商代碼繳費",
      content: [
        "(1)超商代碼繳費，即收款方透過本平台產生一次性之代碼，提供予付款方至超商多媒體機列印繳費單後，於超商櫃台以現金全額支付之收款方式。",
        "(2)收款方經本服務成功收取付款方以超商代碼繳費為付款方式之交易款項後，若允許付款方辦理退貨、取消交易，本功能不提供退款機制，收款方應自行退款予付款方，且該交易計收之手續費將無法返還收款方。",
        "(3)依超商通路服務約定，遊戲點數、遊戲點卡或遊戲點數儲值類型產品、及電信點卡類之商品不得使用本支付方式收款，若違反經本公司或超商通路證實，則本公司有權隨時終止向收款方提供此支付方式且凍結該收款方式所產生之交易款，若因收款方違反約定造成本公司任何損失，收款方應付損害賠償之責，不得異議。",
        "(4)付款方應於超商多媒體機所列印之繳費單上所載之繳款期限完成繳款，逾期者，該繳費單將自動失效。",
        "(5)本功能所產生之代碼，於超商多媒體機列印繳費單後僅接受繳款一次，如付款方因重覆繳款所生之損害，由付款方自行承擔。",
      ],
    },
    {
      title: "3. 超商條碼繳費",
      content: [
        "(1)條碼繳費，即收款方將透過本平台產生之條碼帳單，提供予用戶自行列印後，至超商以現金全額支付之收款方式。",
        "(2)收款方經本服務成功收取付款方以條碼繳費為付款方式之交易款項後，若允許付款方辦理退貨、取消交易，本功能不提供退款機制，收款方應自行退款予付款方，且該交易計收之手續費將無法返還收款方。",
        "(3)條碼繳費為延遲核銷型收款服務，條碼繳費之核銷須視各通路回應狀態依序更新。",
        "(4)為確保辨識正確性，用戶應使用雷射印表機列印條碼繳款單，若因列印設備規格未達要求致使無法完成繳付，本公司不負任何責任與損失。用戶對任一服務內容、收款方式、服務費用等規範有疑義或反對者，均應立即停止使用該服務內容、收款方式或本服務之功能，一旦用戶使用任一服務內容、收款方式，即表示同意相關服務規範條款。",
      ],
    },

    {
      title: "五、支付款項之保障",
      content: [
        "本公司就本服務所代收之交易款項，已全部存入與金融機構信託業者簽訂信託  契約所約定之信託專戶，專款專用。相關之金融機構及信託期間，依本公司網  站之訊息為準。",
      ],
    },
    {
      title: "六、支付錯誤之處理",
      content: [
        "(一)因不可歸責於用戶之事由而發生支付錯誤時，本公司將協助用戶進行更正，並提供其他必要之協助。",
        "(二)因可歸責於本公司之事由而發生支付錯誤時，本公司將於知悉時，立即更正，並同時以電子郵件方式通知用戶。",
        "(三)因可歸責於用戶之事由而發生支付錯誤時，本公司經用戶通知後，將立即協助處理。",
      ],
    },
    {
      title: "七、服務費用",
      content: [
        "(一)交易手續費:收款方使用本服務時，本公司將會依照付款方所選擇之付款方式，向收款方收取交易手續費，手續費費率以【銷售中心商店管理交昜手續費及撥款天數】公告之內容為準，各筆代收交易處理費，小數點以下四捨五入，單筆不滿新臺幣一元以一元計算。",
        "(二)本公司將每個月彙總上個月之手續費金額，並於每月月底提供電子發票予收款方。",
        "(三)提領手續費:收款方將由本服務代收之交易款項，由阜冬衫金流帳戶轉出至已約定之實體銀行帳戶時，相關提領之手續費，以本公司網站上公告之內容為準。",
        "(四)其他費用:帳戶設定費、帳戶年費、驗證費等其他費用，將依後續市場狀況予以調整。",
        "(五)相關服務費用/率若有變更，本公司將於各該服務費用/率變更生效日三十日前於本服務網站公告，若用戶對該費用/率變更有疑義或反對者，應立即停止使用本服務之功能，如用戶繼續使用則視為用戶同意前述變更。",
      ],
    },
    {
      title: "八、收款額度限制",
      content: [
        "(一)為維護並提升網際網路交易安全，本公司對收款方訂有每月收款額度上限。",
        "(二)本公司將依照收款方於本服務之使用情況，不定期調整其收款額度，且本公司將於本服務網站內依該收款方之實際情形顯示目前每月收款額度上限及剩餘可收取款項之額度。",
        "(三)收款方若有較高收款額度需求，需依本服務網站指示另向本公司提出申請，惟收款方對本服務所訂之收款額度上限不得異議。",
        "(四)收款方若因該月收款已達收款額度上限而無法進行收款，不得以任何理由向本公司提出因此造成其收入減損、營業損失及請求損害賠償之主張。",
      ],
    },
    {
      title: "九、代收款項支付流程及提領方式",
      content: [
        "(一)各收款方式之支付流程、代收款項結算依本公司網站上公告之內容為準。撥款週期則以【銷售中心商店管理交昜手續費及撥款天數】公告之內容為準。",
        "(二)代收款項於撥款前，均先逐筆扣除本服務之相關費用後，再撥款至收款方之阜冬衫金流帳戶。",
      ],
    },
    {
      title: "十、阜冬衫金流帳戶使用規範",
      content: [
        "(一)因本服務所生之退款、經主管機關指示、或因發卡機構或收單機構主張而須返還之交易款項，或因使用本服務對本公司所負之損害賠償責任，本公司將自收款方暫留於本平台之款項中扣除，若收款方暫無留存於本平台之款項，本公司將以未來代收款方所收之款項抵付。如該筆賠償金額於六十日內仍未扣抵完畢，收款方應於收到本公司通知後，依本公司指定之方式將未扣抵之金額返還予本公司，否則本公司將依法進行催收。",
        "(二)收款方應定期將阜冬衫金流帳戶內之款項轉出至已約定認證之實體銀行帳戶中。若停留之款項超過一定期間，本公司有權強制將用戶阜冬衫金流帳戶內之款項轉出至前開約定之實體帳戶，並扣除因款項轉出衍生之手續費用。前述一定天期將依本公司網上公告之內容為準。",
        "(三)本公司為確認收款方所提供之實體銀行帳戶之正確性及有效性，將匯入新臺幣一元予該帳戶中，以進行帳戶認證。通過認證後，始屬上述之「已約定認證之實體銀行帳戶」，收款方始得進行款項提領。",
      ],
    },
    {
      title: "十一、平台服務使用規範",
      content: [
        "(一)用戶使用本服務前，請先確認本服務正確之網址(https://fudspay.com/)，方使用本服務，以維護交易安全。",
        "(二)收款方應擔保其依法擁有必要之資格、權利或許可，得合法經營其業務及商品、服務內容，且應完全遵守本服務條款之規定，如因違反前述規定，包括但不限於包裝、公司名稱、商標、說明、圖案或其他書面資料侵害第三人之商標(含聯合、防護商標及服務標章)專用權、專利權、著作權、營業秘密或其他權利之情事，而遭第三人向本公司主張權利，或造成本公司或第三人損害，概由收款方負責。",
        "(三)本公司為釐清交易資訊之正確性，得向收款方查詢交易資料，收款方有詳盡陳述及於二日內提供必要文件之義務。收款方應妥善保管交易相關單據（包含但不限於出貨託運單據、進貨憑證、發票存根等）至少五年，收款方逾期提供、提供不完全或提供虛偽資料時，本公司對於該爭議款項將不負付款之責並保留終止提供本服務之權利。",
        "(四)本公司價金保管期間，如用戶間發生詐欺等不法情事或消費糾紛，本公司將依付款方所提供之報案三聯單或消費爭議申訴證明文件，暫停撥付該筆交易款項或自收款方暫留於本平台之款項中扣除，並依司法機關之終局判決或用戶間之和解文件，作為返還或支付交易款項之依據。用戶不得因該筆款項之暫停撥付或自收款方暫留於本平台之款項中扣除而對本公司主張任何孳息或損害賠償。",
        "(五) 會員應於註冊時，配合提供相關之資料及文件(包含但不限於：姓名或公司  名稱、電子郵件信箱位址、電話、營利事業統一編號/身分證統一編號等)，並  維持該資料之正確性，以利查核會員身份。若會員未提供、提供不完全、提供  虛偽資料或提供之資料日後有變更而未自行或洽本公司客服進行更新時，本公  司有保留接受新會員註冊與否，或於知悉會員提供虛偽資料後終止提供本服務  之權利，如因此造成本公司之損害，本公司將依法主張權利。",
        "(六)用戶應確保用戶登記於本服務之電子郵件信箱位址（E-mail Address）為正常使用狀態，並應定期檢視其內容。本公司將於每筆款項支付完成後，以電子郵件形式通知付款方支付明細，但各該筆代收款項之實際處理情形、狀態，仍以本服務系統所記錄者為準，本公司亦會提供支付明細查詢網頁供用戶隨時查詢。用戶應即時查閱本服務之相關通知訊息。",
        "(七 )收款方應於交易時告知付款方該筆交易產品或服務之相關資訊如下: ",
        "1.商品價格:交易金額(內含營業稅)及幣別。",
        "2.商品內容:提供之商品或服務之內容。",
        "3.聯絡方式:客戶服務窗口(聯絡電話及 E-mail Address). ",
        "4.寄送方式:商品寄送之方式、時間(快遞、郵寄、郵寄費用及應由負擔等) ",
        "5.退換貨條件:退換方式及條件。",
        "6.猶豫期間:有關「商品猶豫期間」之訊息及期間。",
        "7.其他條件:商品是否有進出國限制額外稅賦(依他國法令而有不同者須做特別說  明)。",
        "(八)收款方使用本服務，均應遵照中華民國稅法相關規定辦理。",
        "(九)收款方不得販售或提供依現行國內法令或國際組織規定禁止販售或提供之商品或服務。",
        "(十)收款方如為實體通路商店，應於營業現場明顯處揭露交易支付服務係由本公司所提供。",
        "(十一)收款方如為實體通路商店，因簽帳交易而提供予付款人之簽帳單至少載明收單機構名稱、本公司名稱、收款方名稱、卡別、卡號、授權號碼、交易日期及金額、且卡號之揭露方式應依主管機關之規定辦理，並妥善保管資料及簽單。",
        "(十二)本公司將提供收款方必要之教育訓練及法令宣導。",
        "(十三)收款方如為發行禮券之收款人時，本公司將審酌收款人對其電子儲值型禮券是否有適當之餘額揭露方式，以利付款方知悉其禮券餘額。",
        "(十四)本公司將對於收款方建立交易與請款異常情事之監控機制及定期查核(書  面及實地查核)管理機制，且將會同收單機構對收款人每年進行二次以上專案實  地抽核。如本公司發現收款方有異常情形，將視情節輕重，進行必要處置。  ",
        "(十五)為了防止收款人之刷卡設遭破壞、偷竊交易資料或擅自修改相關程式，本公司將採取控管措施。",
        "(十六)收款方不得將特約商店代號或刷卡設備轉予他人使用，或向他人借入特約商店代號或刷卡設備。",
      ],
    },
    {
      title: "十二、退款處理",
      content: [
        "(一)信用卡支付",
        "1.交易款項未逾交易日九十天，且收款方同意退款予付款方，收款方應於本服務網站上進行相關退款流程，將由本公司進行原信用卡刷退作業。",
        "2.可針對該筆信用卡交易進行部分金額退款。",
        "(二)超商代碼繳費、條碼繳費",
        "如收款方與付款方達成退款協議，則由收款方與付款方自行協議退款方式。如收款方與付款方無法達成退款協議，本公司將協助雙方進行溝通。",
        "(三)僅信用卡收付款可進行交易金額部分退款。",
      ],
    },
    {
      title: "十三、以電子文件為表示方法",
      content: [
        "(一)本公司與用戶同意以電子文件作為表示方法，依本服務條款交換之電子文件，其效力與書面文件相同。",
        "(二)用戶同意於本服務網站執行功能選項及任何條款之同意與否，均由本公司詳實記錄登入帳號、IP 及時間等資訊，用戶於本服務網站所為之行為均具法律效力。",
        "(三)所有根據本服務條款公布於本服務網站之內容、附加服務使用規範及本服務條款修正內容將以線上同意形式為之。若用戶對本服務、本服務條款及相關使用規範之任一部分有疑義或反對時，應即停止使用本服務之全部，如繼續使用，視為同意本服務條款及相關使用規範之全部。",
      ],
    },
    {
      title: "十四、帳號密碼遭冒用之處理",
      content: [
        "(一)會員應妥善保管用於登入本服務之帳號、密碼，不得以任何方式讓與、轉借或授權他人使用自己之登入身份。任何因可歸責於會員未妥善保管本服務之帳號、密碼而造成之損害（包括但不限於對會員本人、本公司及收/付款方之損害），本公司將不負任何責任，並得向用戶請求賠償。",
        "(二)會員於發現本服務之帳號、密碼遭第三人冒用、盜用之情形時，應立即通知本公司客服(列於本份說明之末)，辦理相關手續，且同意本公司基於保護用戶安全之理由，立即停止該帳號使用本服務，並暫停該帳號所指示及後續指示之支付行為直至該異常狀況解除為止，本公司不負任何因此造成之損害賠償責任。本公司並得要求會員出具相關文件（包含但不限於：警政機關報案證明、切結書、身分證明文件等），以受理帳號、密碼遭第三人冒用之或恢復該帳號之使用權。",
        "(三)會員依本公司規定完成辦理帳號密碼遭冒用之手續後所生之損失，概由本公司負擔;手續辦理完成前所發生之損失，有下列情事者，則由會員自行負擔: ",
        "1.會員未妥善保管帳號密碼。",
        "2.會員自行將帳號密碼提供與他人。",
        "3.會員未使用本服務所提供之帳號安全機制。",
        "4.其他因會員之故意重大過失所致之事由。",
      ],
    },
    {
      title: "十五、交易糾紛處理機制",
      content: [
        "交易糾紛處理機制及申請管道、電話及電子郵件信箱等相關資訊依本公司網站  上公告為準。",
      ],
    },
    {
      title: "十六、保密義務",
      content: [
        "(一)用戶對本服務所取得之交易資訊、交易相對人之資料，包括但不限於信用卡交易資料等、收款額度、費率等，非公開於本服務網站之服務資訊，除其他法律或主管機關另有規定者外，均負有保密義務，不得公開傳播或為任何形式之揭露。若因用戶違反前揭保密義務，本公司有權終止提供本服務，如因而致本公司受有損害，用戶應負賠償之義務。",
        "(二)收款方對付款方之資訊應負保密義務，除完成交易所必須，不得公開傳播或以任何形式揭露，若因此造成付款方或本公司之損害，收款方應負賠償之責。",
      ],
    },
    {
      title: "十七、資訊安全",
      content: [
        "(一)本公司及用戶應各自確保其資訊系統之安全，防止非法入侵、取得、竄改、毀損業務紀錄或消費者之個人資料。",
        "(二)如發生第三人破解本公司資訊系統之保護措施或利用資訊系統之漏洞爭議，將由本公司就該事實不存在負舉證責任。",
        "(三)第三人入侵本公司之資訊系統對用戶所造成之損害，將由本公司負擔。",
      ],
    },
    {
      title: "十八、 條款變更及服務終止",
      content: [
        "一)本服務條款如有修改或增刪時，本公司將於生效日三十日前公告於本服務網站，並以電子郵件通知用戶，用戶若拒絕接受該修改或增刪條款，應立即停止使用本服務，否則即視為同意修改或增刪條款之內容。",
        "(二)若本服務停止全部功能，則本服務條款亦隨同停止，用戶不得異議，亦不得請求損害賠償。",
        "(三)用戶同意本公司基於法令及其他用戶使用本服務權益之考量，於本公司認定用戶有違反本服務條款之情事時，得不經預告、通知而逕行暫停、拒絕或終止用戶使用本服務。",
        "(四)若用戶有下列情事之一者，本公司亦得不經預告、通知而逕行暫停、拒絕或終止用戶使用本服務。",
        "1.依破產法聲請和解、聲請宣告破產、聲請重整、經票據交換所公告拒絕往來、停止營業，或受強制執行假扣押假處分。",
        "2.全部營業資產之所有權或營業轉讓與他人；或部分營業資產之所有權或營業權轉讓與他人，足以影響營運。",
        "3.公司或商號名稱、地址、負責人或實際營業項目變更，未即時通知本公司。",
        "4.從事非法之交易行為、或有調現、拆帳單之嫌疑、或信用卡收款方式中偽冒交易比率過高。",
        "5.無正當理由連續一百八十日無收款記錄。",
        "6.用戶之支付款項經法院裁定或檢察官命令扣押。",
        "7.遭國際組織、發卡機構、收單機構等單位通報為質疑商店，或本公司因可歸責用戶之事由致遭發卡機構主張扣款時，本公司有權暫停用戶使用本服務。8.拒不配合提供必要文件。",
        "8.拒不配合提供必要文件。",
        "9.提交虛偽之身分認證資料，經查證屬實者(包含但不限於：身分證影本、同意  書、切結書等文件)。",
        "10.用戶有其他信用不良或違反本服務條款及相關規範時。",
        "11.有相當事證足認用戶有利用本服務為洗錢、詐欺等犯罪行為或不法行為者。",
        "(五)除本服務條款另有規定外，如用戶因前二項事由遭本公司終止使用本服務，後續未及結算之帳款則另依本網站公告之規定處理。",
        "(六)會員得隨時依照本服務網站所記載之說明，主動辦理終止使用本服務，終止後如需再度使用本服務，則需依本公司規定重新提出申請。",
        "(七)因本條任一項規定終止本服務，就終止前之交易，用戶仍需依本服務條款之規範履行全部權利義務，用戶依據本服務條款而負擔之權利義務，不因本服務之終止而消滅失。如本服務係依本條第四項第 4 款、第 6 款或第 11 款之規定而終止，本公司將暫緩撥付交易款項或自收款方暫留於本平台之款項中扣除，並凍結相關之阜冬衫金流帳戶。用戶不得因該筆款項之暫緩撥付或自收款方暫留於本平台之款項中扣除而對本公司主張任何孳息或損害賠償。",
      ],
    },
    {
      title: "十九、其他",
      content: [
        "(一)若本服務因系統維護、搬遷、升級等事由需中止服務時，本公司將於七日前於本服務網站公告。",
        "(二)用戶同意並認知，網際網路服務系統穩定度涉及諸多因素，本公司將盡力維護本服務之妥善性，惟若天災、停電、設備故障等不可歸責於本公司之事由致造成無預警之服務中斷，本公司就用戶之損失不負損害賠償責任。",
        "(三)因網際網路用戶端作業軟體、瀏覽器版本等環境有諸多變因，本公司不保證用戶於所有用端環境（包含但不限於電腦、上網終端設備之硬體、作業系統、瀏覽器版本等）之正常操作，用戶亦不得據此向本公司主張任何權利。",
        "(四)若因用戶端伺服器、電腦等設備異常，而經本公司偵測認定有影響本服務對他人服務順暢之虞者，本公司得不經通知逕行中斷用戶與本服務間之網路連結，用戶不得向本公司主張任何異議或主張因此致生之損害賠償責任。",
        "(五)用戶不因使用本服務而取得本公司任何智慧財產權之明示或默示之授權，且非事先經本公司書面同意，用戶不得使用、重製、或散布本服務網站上任何形式的資料、商標或標識。",
        "(六)用戶瞭解停留於本服務之所有代收、代付、保管款項並非存款性質，不受存款保險之保障，亦無利息產生。",
        "(七)如本公司合理懷疑用戶於本服務之使用涉及疑似不當或不法情事，本公司得逕行凍結其帳號並停止提供本服務。",
        "(八)本服務條款如有未盡事宜，均依相關金融法規、主管機關規定辦理。信用卡收款相關未盡事宜，亦應依信用卡國際組織之相關規範辦理。",
        "(九)本公司將配合金融監理機關、警政機關暫停帳戶之使用、停止款項撥付等要求，用戶不得因此向本公司主張損害賠償責任。",
        "(十)本服務代收款項，一律僅得撥付至收款方持有且已經認證之銀行實體存款帳戶。收款方不得因該帳戶因故遭凍結、設為警示帳戶等事由，而要求本公司將款項撥付至其他未經認證之帳戶中。",
        "(十一)本服務網站提供交易資料查詢功能之期間為自交易訂單成立後一年，逾一年之交易資料等訊息均應依本服務網站公佈之申請方式申請查閱，並負擔相關費用。",
        "(十二)用戶同意本公司為履行本服務之目的範圍內或依法得將用戶之基本資料、交易記錄等資訊提供行政院金融監督管理委員會、信用卡國際組織、業務合作機構及其他有權監督機構進行必要查核或使用，相關規範請參考本公司官方網站上之《隱私權條款》。",
        "(十三)會員同意本公司於本服務之必要範圍內得向財團法人金融聯合徵信中心及其他有關機構查詢蒐集用戶資料，並處理或利用之。",
        "(十四)用戶非事先經本公司書面同意，不得將因本服務條款所生之權利或義務之一部或全部轉讓予第三人。",
        "(十五)本服務條款之解釋如有疑義時，依有利於用戶之解釋為之。",
      ],
    },
    {
      title: "二十、 管轄法院及準據法",
      content: [
        "(一)本服務條款以中華民國法令為準據法。",
        "(二)用戶與本公司間因本服務、本服務條款及本服務之相關規範所生之爭議，如因此而涉訟，同意以臺灣臺北地方法院為第一審管轄法院。 ",
      ],
    },
    {
      title: "",
      content: [
        "本公司相關資訊如下:",
        "名稱: 揚盛股份有限公司",
        "負責人: 李勁寬",
        "網址: https://fudspay.com/",
        "地址:臺北市信義區信義路五段 7 號 37 樓",
        "電話:02-7753-3533",
        "(2)電子郵件信箱: service@yangyangpay.com ",
        "(3)服務時間: 全年無休",
        "電子信箱客服時間:全年無休",
      ],
    },
  ];
  return (
    <Box>
      <Container maxWidth="lg">
        <Title title="阜冬衫支付平台服務條款" />

        <Box bgcolor="#fff" my={2} p={isTablet ? 2 : 4}>
          {info.map((item) => {
            return (
              <>
                <Box my={isTablet ? 2 : 4}>
                  <Typography
                    gutterBottom
                    variant={isTablet ? "h6" : "h4"}
                    style={{ color: "#3facff", fontWeight: 700 }}
                  >
                    {item.title}
                  </Typography>
                </Box>
                <ul
                  style={{
                    textIndent: isTablet ? "0em" : "2em",
                    listStyle: "none",
                    margin: "1em 0",
                  }}
                >
                  {item.content.map((subItem) => {
                    return (
                      <li style={{ margin: "0.7em 0" }}>
                        <Typography
                          gutterBottom
                          component="p"
                          variant={isTablet ? "caption" : "body1"}
                        >
                          {subItem}
                        </Typography>
                      </li>
                    );
                  })}
                </ul>
              </>
            );
          })}
        </Box>
      </Container>
    </Box>
  );
}
