import React from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export default function FraudPreventionPage() {
  return (
    <div>
      <FraudPreventionContent />
    </div>
  );
}

function Title({ title }) {
  const theme = useTheme();
  const isTablet = useMediaQuery("(max-width:960px)");
  const useStyles = makeStyles({
    title: {
      fontWeight: "bold",
      paddingBottom: "10px",
      borderBottom: `5px solid ${theme.palette.primary.main}`,
    },
  });
  const classes = useStyles();
  return (
    <Box my={6} display="flex" justifyContent="center">
      <Typography variant={isTablet ? "h5" : "h4"} className={classes.title}>
        {title}
      </Typography>
    </Box>
  );
}

function FraudPreventionContent() {
  const theme = useTheme();
  const isTablet = useMediaQuery("(max-width:960px)");
  const useStyles = makeStyles({});
  const classes = useStyles();
  const info = [
    {
      title: "防詐騙3不3C原則",
      subTitle: "3不原則",
      content: ["近來網路購物詐騙猖獗，提醒您，"],
      subContent: [
        "不會要求您設定「約定帳號」。",
        "不會要求您一邊接電話、一邊操作ATM。",
        "不會以臉書/LINE/簡訊/電話通知您「線上誤刷卡」「分期付款設定錯誤」並要求您至ATM、CDM存款機操作「退款」。",
      ],
    },
    {
      title: "",
      subTitle: "3C原則",
      content: [],
      subContent: [
        `保持冷靜（Calm down）
遇到突發情形或有意外之財時，應先保持冷靜
        `,
        `小心查證（Check it out）
收到可疑簡訊訊息，謹慎面對，再三確認。
        `,
        `撥165專線（Call 165）
仍有疑問，迅速撥打刑事警察局165反詐騙專線
        `,
      ],
    },
    {
      title: "",
      subTitle: "",
      content: [
        "預防詐騙三不原則：不理會、不回電、不依指示操作ATM",
        "千騙、萬騙，就是不離ATM",
      ],
      subContent: [],
    },
    {
      title: "",
      subTitle: "",
      content: [
        "內政部防詐騙短片(https://www.youtube.com/watch?v=_rW1yR9uZfY)",
      ],
      subContent: [],
    },
    {
      title: "",
      subTitle: "",
      content: [
        "如您接到疑似詐騙電話或簡訊",
        "請直接撥打警政署防詐騙專線「165」查證。",
        "更多資訊請參考",
        "警政署防詐騙網站 https://www.165.gov.tw/",
      ],
      subContent: [],
    },
  ];

  const subInfo = [
    {
      title: "",
      subTitle: "",
      content: ["因目前電子商務蓬勃發展，網路交易向來是目前各大商店的核心業務，但無論是販售任何消費商品，都有遭受網路詐騙的風險。壹壹柒柒科技分享的網路詐騙案例，降低詐騙遺憾發生的風險機率，避免不必要的損失。"],
      subContent: [
      ],
    },
    {
      title: "",
      subTitle: "可疑的運送地址？",
      content: [""],
      subContent: [
        "寄送地址為奇特地方，例如：偏遠地址、公共場所(如飯店)。",
        "異常訂單1：有多筆訂單成立且在短時間內皆要求寄送同一地方或同一收件人。",
        "異常訂單2：同一地址多筆訂單的收貨人或收件人皆不同但連絡電話相同(通常發生在偽冒的預付卡)。",
        "異常訂單3：客戶在訂單付款完成後，才要求變更運送地址。",
      ],
    },
    {
      title: "",
      subTitle: "不合常理的訂單內容？",
      content: [""],
      subContent: [
        "新客戶訂購數量遠高於平常交易量平均值。",
        "同一客戶或新客戶在短時間內下大量訂單（相同聯繫電子郵件或電話）。",
        "同一客戶交易訂單包含多件相同商品或不同商品。",
        "在短時間內接到異常大量的跨國訂單或國外信用卡交易。",
        "交易時間異常(譬如半夜)接到異常大量的訂單。",
      ],
    },
    {
      title: "",
      subTitle: "",
      content: ["揚盛股份有限公司除了本身的安全技術之外，如客戶對信用卡有需要更安全的交易模式，本公司也與銀行也有建立信用卡3D交易安全模式，除可透過本公司的技術之外讓客戶的損失也極力將風險降到最低，以達到多方皆獲得所需要的目標。"],
      subContent: [
      ],
    },
    {
      title: "",
      subTitle: "",
      content: [
        "免責聲明",
        "以上所列的資訊建議僅供建議及參考，非專業及有法律效用。不保證此處分享之內容符合最新狀況及完整性，如過度倚賴以上資訊所提供之資料而造成損失，不承擔任何法律或賠償責任。",
    ],
      subContent: [
      ],
    },
  ];

  
  return (
    <Box>
      <Container maxWidth="lg">
        <Title title="反詐騙提醒(付款方)" />

        <Box bgcolor="#fff" my={2} p={isTablet ? 2 : 4}>
          {info.map((item) => {
            return (
              <>
                <Box my={isTablet ? 2 : 4}>
                  <Typography
                    gutterBottom
                    variant={isTablet ? "h6" : "h4"}
                    style={{ color: "#3facff",fontWeight:700 }}
                  >
                    {item.title}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    gutterBottom
                    variant={isTablet ? "subtitle1" : "h6"}
                    style={{ color: "#3facff",fontWeight:700 }}
                  >
                    {item.subTitle}
                  </Typography>
                </Box>
                <ul
                  style={{
                    listStyle: "none",
                    margin: "1em 0",
                  }}
                >
                  {item.content.map((subItem) => {
                    return (
                      <li style={{ margin: "0.7em 0" }}>
                        <Typography
                          gutterBottom
                          component="p"
                          variant={isTablet ? "caption" : "body1"}
                        >
                          {subItem}
                        </Typography>
                      </li>
                    );
                  })}
                </ul>
                <ul style={{fontSize:'1.5em',marginLeft:'1em'}}>
                {item.subContent.map((subItem) => {
                    return (
                      <li style={{ margin: "0.7em 0" }}>
                        <Typography
                          gutterBottom
                          component="p"
                          variant={isTablet ? "caption" : "body1"}
                        >
                          {subItem}
                        </Typography>
                      </li>
                    );
                  })}
                </ul>
                
              </>
            );
          })}
        </Box>
      </Container>
      <Container maxWidth="lg">
        <Title title="反詐騙提醒(收款方)" />

        <Box bgcolor="#fff" my={2} p={isTablet ? 2 : 4}>
          {subInfo.map((item) => {
            return (
              <>
                <Box my={isTablet ? 2 : 4}>
                  <Typography
                    gutterBottom
                    variant={isTablet ? "h6" : "h4"}
                    style={{ color: "#3facff",fontWeight:700 }}
                  >
                    {item.title}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    gutterBottom
                    variant={isTablet ? "subtitle1" : "h6"}
                    style={{ color: "#3facff",fontWeight:700 }}
                  >
                    {item.subTitle}
                  </Typography>
                </Box>
                <ul
                  style={{
                    listStyle: "none",
                    margin: "1em 0",
                  }}
                >
                  {item.content.map((subItem) => {
                    return (
                      <li style={{ margin: "0.7em 0" }}>
                        <Typography
                          gutterBottom
                          component="p"
                          variant={isTablet ? "caption" : "body1"}
                        >
                          {subItem}
                        </Typography>
                      </li>
                    );
                  })}
                </ul>
                <ul style={{fontSize:'1.5em',marginLeft:'1em'}}>
                {item.subContent.map((subItem) => {
                    return (
                      <li style={{ margin: "0.7em 0" }}>
                        <Typography
                          gutterBottom
                          component="p"
                          variant={isTablet ? "caption" : "body1"}
                        >
                          {subItem}
                        </Typography>
                      </li>
                    );
                  })}
                </ul>
                
              </>
            );
          })}
        </Box>
      </Container>
    </Box>
  );
}
