import React, { createElement, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";

import Layout from "./components/Layout";
import { CssBaseline } from "@material-ui/core";
import HomePage from "./pages/HomePage.js";
import ContactUsPage from "./pages/ContactUsPage.js";
import LoginPage from "./pages/LoginPage.js";
import RegisterPage from "./pages/RegisterPage.js";
import ForgotPasswordPage from "./pages/ForgotPasswordPage.js";
import OnlinePaymentPage from "./pages/OnlinePaymentPage.js";
import ChargePage from "./pages/ChargePage.js";
import ReceivedPaymentPage from "./pages/ReceivedPaymentPage.js";
import PayPage from "./pages/PayPage.js";
import CreditCardPage from "./pages/CreditCardPage.js";
import PrivacyPage from "./pages/PrivacyPage";
import ATMPage from "./pages/ATMPage";
import ConvenienceStorePage from "./pages/ConvenienceStorePage";
import ServicePage from "./pages/ServicePage";
import FraudPreventionPage from "./pages/FraudPreventionPage";
import TransactionPage from "./pages/TransactionPage";

export default function RouteComponent() {
  const routeList = [
    { path: "/", component: HomePage },
    { path: "/contact-us", component: ContactUsPage },
    { path: "/log-in", component: LoginPage },
    { path: "/register", component: RegisterPage },
    { path: "/forgot-password", component: ForgotPasswordPage },

    { path: "/online-payment", component: OnlinePaymentPage },
    { path: "/charge", component: ChargePage },
    { path: "/received-payment", component: ReceivedPaymentPage },

    { path: "/pay", component: PayPage },

    { path: "/credit-card", component: CreditCardPage },
    { path: "/privacy", component: PrivacyPage },

    { path: "/atm", component: ATMPage },

    { path: "/convenience-store", component: ConvenienceStorePage },
    { path: "/service", component: ServicePage },
    { path: "/fraud-prevention", component: FraudPreventionPage },
    { path: "/transaction", component: TransactionPage },
  ];

  return (
    <>
      <CssBaseline />
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path={routeList.map((item) => item.path)}>
            <Layout>
              {routeList.map(({ path, component }) => (
                <Route key={path} path={path} exact>
                  {createElement(component)}
                </Route>
              ))}
            </Layout>
          </Route>
        </Switch>
      </Router>
    </>
  );
}

// ANCHOR 換頁重置滾動
function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}
