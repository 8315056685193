import React from "react";
import Route from "./Route";
import ThemeProvider from "./ThemeProvider";
import { TokenProvider } from "./components/TokenProvider";
export default function App() {
  return (
    <ThemeProvider>
      <TokenProvider>
        <Route />
      </TokenProvider>
    </ThemeProvider>
  );
}
