import React from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export default function PrivacyPage() {
  return (
    <div>
      <PrivacyContent />
    </div>
  );
}

function Title({ title }) {
  const theme = useTheme();
  const isTablet = useMediaQuery("(max-width:960px)");
  const useStyles = makeStyles({
    title: {
      fontWeight: "bold",
      paddingBottom: "10px",
      borderBottom: `5px solid ${theme.palette.primary.main}`,
    },
  });
  const classes = useStyles();
  return (
    <Box my={6} display="flex" justifyContent="center">
      <Typography variant={isTablet ? "h5" : "h4"} className={classes.title}>
        {title}
      </Typography>
    </Box>
  );
}

function SubTitle({ subTitle }) {
  const theme = useTheme();
  const isTablet = useMediaQuery("(max-width:960px)");
  const useStyles = makeStyles({
    title: {
      fontWeight: "bold",
      paddingBottom: "10px",
    },
  });
  const classes = useStyles();
  return (
    <Box>
      <Typography variant="h6" className={classes.title}>
        {subTitle}
      </Typography>
    </Box>
  );
}
function PrivacyContent() {
  const theme = useTheme();
  const isTablet = useMediaQuery("(max-width:960px)");
  const useStyles = makeStyles({});
  const classes = useStyles();
  const privacyInfo = [
    {
      content: [
        "揚盛股份有限公司（以下簡稱本公司），擬定「隱私權保護政策」以保障您的個人資料及隱私權，並讓您瞭解本公司對您的個人資料，其蒐集目的及個人資料類別，個人資料利用之期間、地區、對象及方式、您可以行使的權利與方式、及您無法提供完整的個人資料時或不提供時，可能對您個人權益之影響及個人資料的安全維護機制。",
      ],
    },
    {
      title: "一、適用範圍",
      content: [
        "本公司「隱私權條款」適用於您於本公司網站(https://fudspay.com)及網站內由本公司提供連結之附屬網站、網頁活動時，所涉及的個人資料蒐集、處理、利用、國際傳遞以及保護措施。惟不包括經由本公司網站而連結至第三人經營的網站或網頁。",
      ],
    },
    {
      title: "二、蒐集資料之目的",
      content: [
        "本公司蒐集您的個人資料，以下有關蒐集目的所列代號及項目係參照法務部公告之「個人資料保護法之特定目的及個人資料之類別」所為之編號及分類：",
        "059 金融服務業依法令規定及金融監理需要，所為之蒐集處理及利用",
        "060 金融爭議處理",
        "061 金融監督、管理與檢查",
        "063 非公務機關依法定義務所進行個人資料之蒐集處理及利用",
        "067 信用卡、現金卡、轉帳卡或電子票證業務",
        "069 契約、類似契約或其他法律關係事務",
        "075 科技行政",
        "090 消費者、客戶管理與服務",
        "091 消費者保護",
        "148 網路購物及其他電子商務服務",
        "157 調查、統計與研究分析",
        "181 其他經營合於營業登記項目或組織章程所定之業務",
      ],
    },
    {
      title: "三、蒐集資料之類別",
      content: [
        "本公司蒐集您的個人資料，蒐集之類別依照法務部公告之「個人資料保護法之特定目的及個人資料之類別」所為分類：",
        "C001 姓名、住址、行動電話、電子郵遞地址",
        "C002 金融機構帳戶之號碼與姓名、信用卡或簽帳卡之號碼、個人之其他號碼或帳戶",
        "C003 身分證統一編號、統一證號、稅籍編號",
        "C011 年齡、性別、出生年月日",
        "C036 生活格調、消費習慣",
        "C093 財務交易、支付方式、往來紀錄",
        "C101 資料主體之商業活動",
        "C102 約定或契約",
        "C119 對利益團體之支持",
        "C120 宗教信仰",
        "C121 其他信仰",
        "C132 會員註冊時間、IP位址、瀏覽器種類、網頁瀏覽軌跡紀錄、Cookies、手機GPS定位、停留時間、Wi-Fi 定位",
      ],
    },
    {
      title: "四、資料利用之期間、地區、對象及方式",
      content: [
        "(一) 期間：",
        "除法令另有規定者外，自您開始註冊之日起，至您或本公司終止本公司服務之日止，為個人資料利用之期間，但若法令或函示另有規定者，從其規定。",
        "(二) 地區：",
        "臺灣地區及提供本公司網站及其所附屬網站、網頁服務之地區。",
        "(三) 對象：",
        "除法令另有規定者外，本公司將僅限於您同意之服務業務與目的及在相關聯之範圍內使用。但基於履行服務業務及因服務衍生之相關問題（例如消費爭議等相關糾紛時），本公司將提供相關資料予或業務往來之廠商、金融機構、依法有權機關，以完成履行服務及附隨義務。",
        "(四) 方式：",
        "以自動化機器或其他非自動化之利用方式。",
      ],
    },
    {
      title: "五、您得行使的權利及方式",
      content: [
        "(一) 您得就其個人資料依個人資料保護法規定行使之下列權利：",
        "1.	查詢或請求閱覽。",
        "2.	請求製給複製本。",
        "3.	請求補充或更正。",
        "4.	請求停止蒐集、處理或利用。",
        "5.	請求刪除。",
        "(二) 權利限制：",
        "以上權利，若因您不符合本公司申請程序或依法本公司有執行業務之必需時，得不依照您的請求。",
        "(三) 酌收費用：",
        "若您就您的個人資料向本公司進行查詢、請求閱覽個人資料或製給複製本時，本公司將酌收必要成本費用。",
        "(四) 權利行使之方式：",
        "上開權利之行使，本公司得向您請求提出可資確認之身分證明文件，應填具相關申請文件。若委託他人代為申請者，並應出具委任書，且提供本人及代理人之身分證明文件。",
      ],
    },
    {
      title: "六、若您無法提供完整個人資料，所致權益之影響",
      content: [
        "您若不提供完整而正確的個人資料，或不符合本公司申請程序及法令相關規定時，上述權利將會受到限制，以及可能無法使用網站部份功能、服務、活動。屆時本公司將無法受理您申請會員資格、相關金流業務資格之申請。若因您未提供完整且確實個人資料，造成對您及第三人發生損害時，您應負擔一切責任，如造成本公司的損失時，本公司有權向您請求損害賠償。",
      ],
    },
    {
      title: "七、與第三人共用個人資料之政策",
      content: [
        "本公司在符合法令規範及依循下列原則之前提下，將透過本服務網站所蒐集之您的個人資料提供予第三者。",
        "1.	(一) 經您同意接受，本公司提供給您由本公司與第三者合作之服務或優惠權益，且需要與該第三者共用您的個人資料。",
        "2.	(二) 司法、金融監理、稅務及其他依法具調查權之公務機關，依法要求本公司提供您的個人資料時，本行將於確認該公務機關已循合法程序，並兼顧消費者權益考量下，於特定目的必要範圍內配合。",
      ],
    },
    {
      title: "八、本公司傳送商業資訊或電子郵件之政策",
      content: [
        "1.	(一) 本公司將在事前或經由註冊方式取得您的同意後，透過本網站傳送商業性資料或電子郵件至您留存於本行之電子郵件信箱。",
        "2.	(二) 您可隨時停止接收這些資料或電子郵件。",
      ],
    },
    {
      title: "九、本公司運用Cookie之政策",
      content: [
        "1.	(一) Cookies是伺服端為了區別使用者的不同喜好，經由瀏覽器寫入使用者硬碟的一些簡短資訊。您可以在瀏覽器設定中自行修改對Cookies的接受程度，包括接受所有Cookies、設定Cookies時得到通知、拒絕所有Cookies等三種。如果您選擇拒絕所有的Cookies，您可能無法使用部分個人化服務，或是參與部分活動。",
        "2.	(二) 為提供更好、更個人化的服務以及統計瀏覽人數及分析瀏覽模式，以了解網頁瀏覽的情況，做為本網站改善服務的參考，以及方便您參與個人化的互動活動，本網站會在您的瀏覽器中寫入並讀取Cookies。",
      ],
    },
    {
      title: "十、網站對外的相關連結",
      content: [
        "本網站內所設置之對外連結網站不適用本網站的隱私權保護政策，該連結網站中的隱私權保護政策應依其規定。",
      ],
    },
    {
      title: "十一、個人資料安全維護",
      content: [
        "關於您的個人資料檔案，本公司將採行適當之安全措施，並盡力以合理之技術及措施，依相關法令辦理安全維護事項，防止您的個人資料被竊取、竄改、毀損、滅失或洩漏。",
      ],
    },
    {
      title: "十二、隱私權政策的修改",
      content: [
        "本公司保有修訂隱私權條款權利，任何相關修正或變更皆於本公司網站公告。若您於隱私權條款修正或變更後，您仍繼續使用本公司所提供的服務項目，則表示您已閱讀、瞭解並同意接受隱私權聲明修正或變更後之內容。如果您不同意本隱私權款，請您立即停止使用本公司所提供的服務項目。因此，請您密切注意以保障您的權益。",
      ],
    },
    {
      title: "十三、看法與建議聲明",
      content: [
        "您如果對於本公司隱私權政策或與您個人資料有關之相關事項有任何意見或疑問，可以和本公司客服中心聯絡。",
      ],
    },
  ];
  return (
    <Box>
      <Container maxWidth="lg">
        <Title title="隱私權保護政策" />

        <Box bgcolor="#fff" my={2} p={isTablet ? 2 : 4}>
          {privacyInfo.map((item) => {
            return (
              <>
                <Box my={isTablet ? 2 : 4}>
                  <Typography
                    gutterBottom
                    variant={isTablet ? "h6" : "h4"}
                    style={{ color: "#3facff" }}
                  >
                    {item.title}
                  </Typography>
                </Box>
                <ul
                  style={{
                    textIndent: isTablet ? "0em" : "2em",
                    listStyle: "none",
                    margin: "1em 0",
                  }}
                >
                  {item.content.map((subItem) => {
                    return (
                      <li style={{ margin: "0.7em 0" }}>
                        <Typography
                          gutterBottom
                          component="p"
                          variant={isTablet ? "caption" : "body1"}
                        >
                          {subItem}
                        </Typography>
                      </li>
                    );
                  })}
                </ul>
              </>
            );
          })}
        </Box>
      </Container>
    </Box>
  );
}
